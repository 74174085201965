import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';

import CapacityWhatWeDo from './CapacityWhatWeDo';
import styles from './capacitiesListWhatWeDo.module.scss';

export class PureCapacitiesListWhatWeDo extends Component {

  renderCapacitiesList() {
    if (this.props.data.allMarkdownRemark.totalCount == 0) return null;
    const icon_array = this.props.data.icons.edges;

    const getIcon = (capacity) => {
      const matching_icon = icon_array.find(icon => {
        if (icon.node.publicURL.includes(capacity.frontmatter.icon_name))
          return icon;
      });
      return matching_icon.node.publicURL;
    };

    const capacity_components = this.props.data.allMarkdownRemark.edges.map(({node}) => {

      return (
        <li key={node.frontmatter.id} id={node.frontmatter.id}>
          <CapacityWhatWeDo
            key={node.frontmatter.id}
            capacity={node}
            icon={getIcon(node)}
          />
        </li>
      );
    });

    return capacity_components;
  }

  render() {
    const {data} = this.props;
    const {intro} = data;
    const {frontmatter, html} = intro;
    const {intro_title} = frontmatter;

    return (
      <div className={'offset-md-2 col-md-8' + ' ' + styles.capacitiesSection}>
        <div>
          <h2 className={styles.introTitle}>{intro_title}</h2>
          <div className={styles.paragraph} dangerouslySetInnerHTML={{__html: html}} />
        </div>
        <ul className={styles.capacityList} aria-label='company capabitlities'>
          {this.renderCapacitiesList()}
        </ul>
      </div>
    );
  }
}
export const CapacitiesListWhatWeDo = props => (
  <StaticQuery
    query={graphql`
      query {
        intro: markdownRemark(frontmatter: { path: { eq: "/what-we-do-intro/" } }) {
          html
          frontmatter {
            intro_title
          }
        }
        allMarkdownRemark (
          filter: {
            fileAbsolutePath: { regex: "/capacities/" }
          },
          sort: {fields: frontmatter___order}
        ) 
        {
          totalCount
          edges {
            node {
              id
              frontmatter {
                capacity_title
                services_description
                icon_alt
                icon_name
                id
                services_link_text
                services_link_url
                services_success_story
              }
            }
          }
        }
        icons: allFile(
          filter: {relativePath: {regex: "/custom-icons\/capacities/"}},
          ) {
            edges {
              node {
                publicURL
              }
            }
          }
      }
    `}
    render={data => <PureCapacitiesListWhatWeDo {...props} data={data} />}
  />
);

export default CapacitiesListWhatWeDo;