import React, {Component} from 'react';
import {graphql, StaticQuery} from 'gatsby';

import styles from './ctaWhatWeDo.module.scss';

export class PureCtaWhatWeDo extends Component {
  render() {
    const {data} = this.props;
    const {contact, cta} = data;

    return (
      <section className={'row' + ' ' + styles.ctaSection}>
        <div className={'offset-md-2 col-md-8' + ' ' + styles.cta}>
          <h2 className={styles.ctaHeader}>{cta.frontmatter.header}</h2>
          <a
            className={styles.button}
            target="_blank"
            rel="noopener noreferrer"
            href={contact.frontmatter.info_href}
            id="cta-email"
          >
            {cta.frontmatter.button_text}
          </a>
        </div>
      </section>
    );
  }
}

export const CtaWhatWeDo = props => (
  <StaticQuery
    query={graphql`
      query {
        cta: markdownRemark(frontmatter: { path: { eq: "/what-we-do-cta/" } }) {
          html
          frontmatter {
            header
            button_text
          }
        }
        contact: markdownRemark(frontmatter: { path: { eq: "/contact-info/" } }) {
          frontmatter {
            info_href
          }
        }
      }
    `}
    render={data => <PureCtaWhatWeDo {...props} data={data} />}
  />
);


export default CtaWhatWeDo;