import React, {Component} from 'react';
import {FontAwesomeIcon} from '@fortawesome/react-fontawesome';
import {Link} from 'gatsby';

import styles from './capacityWhatWeDo.module.scss';

export class CapacityWhatWeDo extends Component {
  constructor(props) {
    super(props);

    this.state = {
      case_studies_hover: false
    };
  }

  toggleCaseStudiesArrow() {
    this.setState({case_studies_hover: !this.state.case_studies_hover});
  }


  render() {
    const {case_studies_hover} = this.state;
    const {capacity} = this.props;
    const {frontmatter} = capacity;
    const {capacity_title, icon_alt, id, services_description, services_link_text, services_link_url, services_success_story} = frontmatter;

    return (
      <>
        <hr className={styles.divider} />
        <div className={styles.capacityListItem}>
          <div className={'row col-xl-4' + ' ' + styles.iconTitle}>
            <img
              className={styles.customIcon}
              src={this.props.icon}
              alt={icon_alt}
            />
            <div className={'col-xl-9'}>
              <h2 className={styles.capacityTitle}>{capacity_title}</h2>
            </div>
          </div>
          <div className={'col-xl-2'} />
          <div className={styles.descriptionArea}>
            <p className={styles.capacityDescription}>{services_description}</p>
            <h3 className={styles.successStory}>Success Story</h3>
            <p className={styles.capacityDescription}>{services_success_story}</p>
            <Link
              to={`/${services_link_url}`}
              className={styles.caseStudiesLink}
              onMouseEnter={() => this.toggleCaseStudiesArrow()}
              onFocus={() => this.toggleCaseStudiesArrow()}
              onMouseLeave={() => this.toggleCaseStudiesArrow()}
              onBlur={() => this.toggleCaseStudiesArrow()}
              id={`case-study-link-${id}`}
            >
              {services_link_text}
              <FontAwesomeIcon
                icon={case_studies_hover ? ['far', 'long-arrow-right'] : ['fal', 'long-arrow-right']}
                className={'align-middle' + ' ' + styles.arrow}
              />
            </Link>
          </div>
        </div>
      </>
    );
  }
}

export default CapacityWhatWeDo;